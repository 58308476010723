import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    HStack,
    Text,
    useColorMode,
    VStack,
} from '@chakra-ui/react';
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';

import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';
import ImgTextModule from '../components/ImgTextModule';
import QuadKachelnInvRelations from '../components/QuadKachelnInvRelations';
import FadeInAnimation from '../DefinitionComponents/Animation-Components/FadeInAnimation';
import BlogItem from '../components/BlogItem';
import BlogItem2 from '../components/youtubeitem';

const Blog = () => {
    const { setColorMode } = useColorMode();

    useEffect(() => {
        setColorMode('light');
    });

    const data = useStaticQuery(graphql`
        query getYoutube {
            allWpYoutubeElement(sort: { fields: date, order: DESC }) {
                nodes {
                    date
                    databaseId
                    modified
                    title

                    titel
                    episodeNummer

                    pDFFile {
                        mediaItemUrl
                        filename
                        localFile {
                            relativePath
                        }
                    }
                    subtitel
                    uri
                    linkHrefYoutube
                }
            }
        }
    `);
    const DIDDS = data.allWpYoutubeElement.nodes;
    console.log(DIDDS);
    return (
        <>
            <Helmet>
                <title>tokentus | Blog</title>
                <meta
                    name='description'
                    content='Knowledge Blocks provided by tokentus investment AG. Our Youtube Channel.'
                />
                <link
                    rel='canonical'
                    href='https:/tokentus.com/knowledgeblocks'
                />
            </Helmet>

            <ImgTextModule
                src={'headeroliver.jpg'}
                delay={0.3}
                duration={0.5}
                threshold={0.3}
                initialY={10}
                initialX={10}
                video={false}
                h={{
                    base: '44vh',
                    md: '50vh',
                    lg: '55vh',
                    xl: '65vh',
                    xxl: '90vh',
                }}
                className='objectFitLeft'
                mediaOpacity={0.5}
                objectPosition='left'
                bgColor='brand.black'>
                <Container
                    variant='layoutContainer'
                    mt={8}>
                    <Box p={8}>
                        <FadeInAnimation
                            threshold={0.4}
                            delay={0.4}
                            duration={0.7}
                            initialX={0}
                            initialY={-10}>
                            <Text
                                color='white'
                                fontWeight='normal'
                                fontSize='xl'>
                                tokentus goes YouTube
                            </Text>
                        </FadeInAnimation>
                        <FadeInAnimation
                            threshold={0.4}
                            delay={0.4}
                            duration={0.7}
                            initialX={0}
                            initialY={-10}>
                            <Text
                                color='brand.green'
                                fontWeight='normal'
                                fontSize={{ base: '5xl', lg: '6xl' }}>
                                Knowledge Blocks
                            </Text>
                        </FadeInAnimation>

                        <FadeInAnimation
                            threshold={0.4}
                            delay={1}
                            duration={0.7}
                            initialX={0}
                            initialY={10}>
                            <Text
                                color='brand.white'
                                mb={24}
                                maxW={{ base: '100%', lg: '50%' }}
                                fontSize='2xl'>
                                Aktuelles Wissen rund um Kryptos und Blockchain.
                            </Text>
                        </FadeInAnimation>
                    </Box>
                </Container>
            </ImgTextModule>
            {/* NEWS */}

            <Container
                variant='layoutContainer'
                px={{ base: 0, lg: 10 }}
                py={{ base: 0, lg: 5 }}>
                {DIDDS.map((News) => (
                    <>
                        {' '}
                        {News.pDFFile === null ? (
                            <BlogItem2
                                episodeNummer={News.episodeNummer}
                                textinhalt={News.titel}
                                textintro={News.subtitel}
                                texttitel={News.titel}
                                linkhref={News.linkHrefYoutube}
                            />
                        ) : (
                            <BlogItem2
                                episodeNummer={News.episodeNummer}
                                textinhalt={News.titel}
                                textintro={News.subtitel}
                                texttitel={News.titel}
                                pdflink={News.pDFFile.mediaItemUrl}
                                linkhref={News.linkHrefYoutube}
                            />
                        )}
                    </>
                ))}{' '}
            </Container>
            <QuadKachelnInvRelations />
        </>
    );
};

export default Blog;
