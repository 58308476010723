import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    Text,
    HStack,
    VStack,
    chakra,
} from '@chakra-ui/react';
import GImage from '../DefinitionComponents/Utility/GImage';
import RouterLink from '../DefinitionComponents/Utility/Routing/RouterLink';
import { motion, isValidMotionProp, AnimatePresence } from 'framer-motion';
import moment from 'moment';
import React, { useState } from 'react';
import ImgMaskRevealStatic from '../DefinitionComponents/Animation-Components/ImgMaskRevealStatic';
import InViewTrigger from '../DefinitionComponents/Utility/UX/InViewTrigger';
const ChakraBox = chakra(motion.div, {
    /**
     * Allow motion props and the children prop to be forwarded.
     * All other chakra props not matching the motion props will still be forwarded.
     */
    shouldForwardProp: (prop) => isValidMotionProp(prop) || prop === 'children',
});
function BlogItem2({
    datum,
    textinhalt,
    textintro,
    texttitel,
    headerimage,
    pdflink,
    episodeNummer,
    linkhref,
}) {
    const date = moment(datum).format('DD.MM.YYYY');
    console.log(date);
    const [isToggled, setToggle] = useState(false);
    return (
        <InViewTrigger
            threshold={0.4}
            triggerOnce={true}>
            {(inView) => (
                <Container variant='layoutContainer'>
                    <Box
                        w={{ base: '100%', lg: '100%' }}
                        mt={1}>
                        <Flex
                            wrap='wrap'
                            flexDirection={{ base: 'column', lg: 'row' }}
                            p={{ base: 8, lg: 8 }}>
                            <Flex minW={{ base: '50%', lg: '30%' }}>
                                <Box
                                    minH={{ base: '200px', lg: '100%' }}
                                    minW={{ base: '100%', lg: '100%' }}
                                    bg={'brand.lightgray'}
                                    p={{ base: 3, md: 4 }}
                                    pb={{ base: 0, lg: 8 }}>
                                    <Heading
                                        w={{ base: '100%', lg: '90%' }}
                                        fontSize={{
                                            base: 'xs',
                                            lg: 'sm',
                                        }}
                                        fontWeight='bold'
                                        ml={{ base: '0%', lg: '15%' }}
                                        mb={2}
                                        lineHeight={'shorter'}
                                        color='brand.blue'>
                                        {' '}
                                        Episode
                                    </Heading>
                                    <Box
                                        w={{ base: '100%', lg: '100%' }}
                                        h={{ base: '60%', lg: '80%' }}
                                        px={{ base: 1, md: 8 }}
                                        display={'flex'}
                                        justifyContent={'center'}
                                        flexDirection={'column'}
                                        placeContent={'center'}
                                        alignItems={'center'}
                                        bg={'brand.blue'}>
                                        <Heading
                                            textAlign={'celeftter'}
                                            fontSize={{
                                                base: '2xl',
                                                lg: '7xl',
                                            }}
                                            fontWeight='normal'
                                            lineHeight={'shorter'}
                                            color='white'>
                                            {episodeNummer}
                                        </Heading>{' '}
                                    </Box>
                                </Box>
                            </Flex>
                            <HStack
                                minW={{ base: '50%', lg: '70%' }}
                                flexGrow={1}
                                flexShrink={1}
                                flexBasis={0}
                                px={{ base: 4, lg: 0 }}
                                py={10}
                                alignItems={'flex-start'}
                                bg={'brand.lightgray'}>
                                <Heading
                                    mb={1}
                                    w={{ base: '100%', lg: '70%' }}
                                    fontSize={{
                                        base: 'lg',
                                        lg: '2xl',
                                    }}
                                    fontWeight='normal'
                                    lineHeight={'shorter'}
                                    color='brand.meddarkgray'
                                    dangerouslySetInnerHTML={{
                                        __html: textintro,
                                    }}></Heading>
                                <Flex flexDir={'column'}>
                                    <Button
                                        mt={0}
                                        color='white'
                                        size={'lg'}
                                        fontWeight={'normal'}
                                        px={6}
                                        backgroundColor='brand.medgray'>
                                        <RouterLink
                                            variant='navlink'
                                            isExternal={true}
                                            href={linkhref}
                                            color={'white'}>
                                            <Text
                                                color='brand.white'
                                                size={'lg'}>
                                                Auf YouTube anschauen
                                            </Text>
                                        </RouterLink>
                                    </Button>
                                    {pdflink && (
                                        <a href={pdflink}>
                                            <Button
                                                mt={3}
                                                color='white'
                                                size={'lg'}
                                                fontWeight={'normal'}
                                                px={6}
                                                pr={16}
                                                backgroundColor='brand.meddarkgray'
                                                leftIcon={
                                                    <GImage
                                                        w='14px'
                                                        h='14px'
                                                        src='download-document.svg'
                                                    />
                                                }>
                                                PDF Download
                                            </Button>
                                        </a>
                                    )}
                                </Flex>
                            </HStack>
                        </Flex>
                    </Box>
                </Container>
            )}
        </InViewTrigger>
    );
}

export default BlogItem2;
