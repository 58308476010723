import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    HStack,
    Text,
    useColorMode,
    VStack
  } from "@chakra-ui/react";
  import { Link } from "gatsby";
import React from "react";
import {useState} from "react"
import {
  Scroll,
  useMotionValue,
  useViewportScroll,
  motion,
  useTransform,
} from "framer-motion";
import InViewTrigger from "../DefinitionComponents/Utility/UX/InViewTrigger";
import ImgMaskReveal from "../DefinitionComponents/Animation-Components/ImgMaskReveal";
import GImage from "../DefinitionComponents/Utility/GImage";
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation";
const MotionBox = motion(Box);
function BlogItem() {

    const [isToggled,setToggle] = useState(false);
  return (
    <InViewTrigger threshold={0.4} triggerOnce={true}>
      {(inView) => (
       <Container   variant="layoutContainer">
        <Box w={{ base: "100%", lg: "100%" }} mt={1}>
<Flex wrap="wrap" flexDirection={"row"}>
  <HStack
    visibility={{ base: "visible", lg: "visible" }}
    display={{ base: "flex", md: "flex" }}
    w="50%"
    minH="320px"
    p={0}
    spacing={0}
    bg={"brand.blue"}
  >
    <ImgMaskReveal
      imgsrc={"linkedin/1660637514464.jpeg"}
      initScale={1.5}
      delay={0}
      duration={2}
      threshold={0.4}
      triggerOnce={true}
    />
  </HStack>
  <VStack
    w={{ base: "100%", lg: "50%" }}
    px={10}
    py={10}
    alignItems={"flex-start"}
    bg={"brand.lightgray"}
  >
    <Heading
      fontSize="md"
      fontWeight="normal"
      lineHeight={"none"}
      color="brand.blue"
    >
      18.08.2022
    </Heading>
    <Heading
      w={{ base: "100%", lg: "90%" }}
      fontSize="xl"
      fontWeight="normal"
      lineHeight={"shorter"}
      color="brand.meddarkgray"
    >
      Generic Textheading
    </Heading>
    <Heading
      w={{ base: "100%", lg: "90%" }}
      fontSize="md"
      fontWeight="normal"
      lineHeight={"shorter"}
      color="brand.medgray"
    >
Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quam suscipit alias nulla laboriosam voluptates sunt officiis consequatur fugit corporis reprehenderit quidem possimus, nihil sapiente quas repellendus exercitationem ipsam sint minus.    </Heading>

   {!isToggled && 
      <Button
      onClick={
          () => setToggle(prevValue =>  prevValue ? false : true )
        }
        color="white"
        size={"lg"}
        fontWeight={"normal"}
        px={6}
        backgroundColor="brand.blue"
        >
        Read more
      </Button>
    }
      {isToggled &&  <Box display="">
      <Heading
      w={{ base: "100%", lg: "90%" }}
      fontSize="md"
      fontWeight="normal"
      lineHeight={"shorter"}
      color="brand.medgray"
    >
Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quam suscipit alias nulla laboriosam voluptates sunt officiis consequatur fugit corporis reprehenderit quidem possimus, nihil sapiente quas repellendus exercitationem ipsam sint minus.    </Heading>
<Heading
      w={{ base: "100%", lg: "90%" }}
      fontSize="md"
      fontWeight="normal"
      lineHeight={"shorter"}
      color="brand.medgray"
    >
Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quam suscipit alias nulla laboriosam voluptates sunt officiis consequatur fugit corporis reprehenderit quidem possimus, nihil sapiente quas repellendus exercitationem ipsam sint minus.    </Heading>
</Box>
}


{isToggled &&  
<Box w="100%" >
<svg width="24px" height="24px" viewBox="0 0 24 24"    onClick={
          () => setToggle(prevValue =>  prevValue ? false : true )
        } >
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.5303 14.0303C16.2374 14.3232 15.7626 14.3232 15.4697 14.0303L12 10.5607L8.53033 14.0303C8.23744 14.3232 7.76256 14.3232 7.46967 14.0303C7.17678 13.7374 7.17678 13.2626 7.46967 12.9697L11.4697 8.96967C11.7626 8.67678 12.2374 8.67678 12.5303 8.96967L16.5303 12.9697C16.8232 13.2626 16.8232 13.7374 16.5303 14.0303Z" fill="black"/>
</svg>
</Box>
}
  </VStack>
</Flex>
</Box>

        </Container>
      )}
    </InViewTrigger>
  );
}

export default BlogItem;
